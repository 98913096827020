import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './AbstractService';
import { HttpClient } from '@angular/common/http';
import { ChargeListObject } from '../model/charge-list-object.model';
import { ChargeListService } from './ChargeListService';
import { concatMap, map, toArray } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { PreparationStatus } from '../model/preparation-status.model';

@Injectable({
  providedIn: 'root'
})
export class ChargeListObjectService extends AbstractService<ChargeListObject> {
  protected mainEndpoint = environment.endpoint +  '/v1/charge-list-object';
  protected keys = [ 'chargeListId', 'objectId' ];
  constructor(
      protected http: HttpClient,
      protected chargeListService: ChargeListService
      ) {
    super(http);
  }

  listForActiveChargeListsForJob(jobId: number, isReturn?: boolean): Observable<ChargeListObject[]> {
    return this.chargeListService.activeListForJob(jobId, isReturn).pipe(
      concatMap(list => from(list)),
      concatMap(one => this.list({
          chargeListId: one.id,
          extraProperties: 'storageObject'
        }).pipe(
          map(chargeListObjectList => {
            for (const chargeListObject of chargeListObjectList) {
              chargeListObject.chargeList = one;
            }
            return chargeListObjectList;
          })
        )
      ),
      toArray(),
      map(lists => {
        const res = [];
        for (const list of lists) {
          res.push(...list);
        }
        return res;
      })
    );
  }

  changeObjectList(chargeListObject: ChargeListObject, newChargeListId: number): Observable<ChargeListObject> {
    return this.http.post<ChargeListObject>(`${this.mainEndpoint}/change-list`, {
      chargeListObject,
      newChargeListId
    });
  }

  getPreparationStatus(chargeListObject: ChargeListObject): Observable<PreparationStatus> {
    return this.http.get<PreparationStatus>(`${this.mainEndpoint}/preparation-status`, {
      params: {
        objectId: chargeListObject.objectId as unknown as string,
        chargeListId: chargeListObject.chargeListId as unknown as string
      }
    });
  }
}
