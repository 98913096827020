import { Subject, Observable, of, throwError } from 'rxjs';
import { tap, share } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface CacheContent {
  expiry: number;
  value: any;
}

/**
 * Cache Service is an observables based in-memory cache implementation
 * Keeps track of in-flight observables and sets a default expiry for cached values
 * @export
 */
export class MyCacheService<T> {
  private cache: Map<string | number, CacheContent> = new Map<string | number, CacheContent>();
  private inFlightObservables: Map<string | number, Observable<T>> = new Map<string | number, Observable<T>>();
  // readonly DEFAULT_MAX_AGE: number = 300000;

  constructor(
    private log = !environment.production,
    private readonly DEFAULT_MAX_AGE = 300000) {

  }

  flush() {
    this.cache = new Map<string | number, CacheContent>();
    this.inFlightObservables = new Map<string | number, Observable<T>>();
  }

  /**
   * Gets the value from cache if the key is provided.
   * If no value exists in cache, then check if the same call exists
   * in flight, if so return the subject. If not create a new
   * Subject inFlightObservable and return the source observable.
   */
  get(key: string | number, fallback?: Observable<T>, maxAge?: number): Observable<T> | Subject<T> {
    if (this.hasValidCachedValue(key)) {
      if (this.log) {
        console.log(`%c Getting from cache ${key}`, 'color: green');
      }
      return of(this.cache.get(key).value);
    }

    if (this.inFlightObservables.has(key)) {
      return this.inFlightObservables.get(key);
    } else if (fallback && fallback instanceof Observable) {

      if (!maxAge) {
        maxAge = this.DEFAULT_MAX_AGE;
      }

      if (this.log) {
        console.log(`%c Calling api for ${key}`, 'color: purple');
      }

      this.inFlightObservables.set(key, fallback.pipe(
        tap((value) => this.set(key, value, maxAge)),
        share()
      ));
      return this.get(key);
    } else {
      return throwError('Requested key is not available in Cache');
    }

  }

  /**
   * Sets the value with key in the cache
   * Notifies all observers of the new value
   */
  set(key: string | number, value: T, maxAge: number = this.DEFAULT_MAX_AGE): void {
    this.cache.set(key, { value, expiry: Date.now() + maxAge });
    if (this.inFlightObservables.has(key)) {
      this.inFlightObservables.delete(key);
    }
  }

  /**
   * Checks if the a key exists in cache
   */
  has(key: string | number): boolean {
    return this.cache.has(key);
  }

  delete(key: string | number): boolean {
    return this.cache.delete(key);
  }

  /**
   * Checks if the key exists and   has not expired.
   */
  private hasValidCachedValue(key: string | number): boolean {
    if (this.cache.has(key)) {
      if (this.cache.get(key).expiry < Date.now()) {
        this.cache.delete(key);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}
