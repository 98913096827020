import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, forkJoin, from, firstValueFrom } from 'rxjs';
import { map, catchError, concatMap, toArray, switchMap } from 'rxjs/operators';
import DevExpress from 'devextreme/bundles/dx.all';
import * as moment from 'moment';
import CustomStore, { Options } from 'devextreme/data/custom_store';

export abstract class AbstractService<T> {

  protected mainEndpoint: string;
  protected keys = ['id'];

  constructor(protected http: HttpClient) { }

  dateToString(date: Date | moment.Moment | string, format?: string) {
    if (date instanceof Date) {
      date = moment(date);
    }
    if (moment.isMoment(date)) {
      format = format || 'YYYY-MM-DD';
      date = date.format(format);
    }
    return date;
  }

  composeObject(rawObject: any): Observable<T> {
    return of(rawObject as T);
  }

  composeObjects(rawObjects: any[]): Observable<T[]> {
    return of(rawObjects).pipe(
      concatMap(objectList => from(objectList)),
      concatMap(object => this.composeObject(object)),
      toArray()
    );
  }

  list(queryParams?: Partial<T> | any): Observable<T[]> {
    return this.http.get<T[]>(this.mainEndpoint + '/list', {
      params: queryParams || {}
    }).pipe(
      catchError(err => {
        console.error(err);
        throw err;
      }),
      concatMap(objectList => this.composeObjects(objectList))
    );
  }

  count(queryParams?: Partial<T> | any): Observable<number> {
    return this.http.get(this.mainEndpoint + '/count', {
      params: queryParams || {}
    }).pipe(
      map((data: any) => data as number),
    );
  }

  get(ids: object | number | Array<number>): Observable<T> {
    let call: Observable<T>;
    if (this.keys.length === 1) {
      switch (typeof ids) {
        case 'object':
          ids = ids[this.keys[0]];
          break;
        case 'number':
        case 'string':
          break;
        default:
          throw new Error(`unable to read Ids for entity`);
      }
      call = this.http.get<T>(`${this.mainEndpoint}/${ids}`);
    } else {
      const keys = {};
      for (const key of this.keys) {
        keys[key] = ids[key];
      }
      call = this.http.get<T>(this.mainEndpoint, {
        params: keys
      });
    }
    return call.pipe(
      switchMap(object => this.composeObject(object))
    );
  }

  insert(modelObject: T): Observable<T> {
    return this.http.post<T>(this.mainEndpoint, modelObject).pipe(
      switchMap(object => this.composeObject(object))
    );
  }

  update(modelObject: T): Observable<T> {
    return this.http.put<T>(this.mainEndpoint, modelObject).pipe(
      switchMap(object => this.composeObject(object))
    );
  }

  updateKeys(oldKeys: T, newValues: T): Observable<T> {
    return this.http.put<T>(this.mainEndpoint + '/keys', {
      old: oldKeys,
      new: newValues
    }).pipe(
      switchMap(object => this.composeObject(object))
    );
  }

  delete(modelObject: T): Observable<boolean> {
    if (this.keys.length === 1) {
      return this.deleteId((modelObject as any)[this.keys[0]]);
    } else {
      const keys = {};
      for (const key of this.keys) {
        keys[key] = modelObject[key];
      }
      return this.http.delete<boolean>(this.mainEndpoint, {
        params: keys
      });
    }
  }

  deleteId(id: any): Observable<boolean> {
    return this.http.delete<boolean>(this.mainEndpoint + '/' + id);
  }

  dataSource(errorMessages?: {
    insertError: string;
    updateError: string;
    removeError: string;
    loadError: string;
  }): Options<T> {
    if (!errorMessages) {
      errorMessages = {} as any;
    }
    errorMessages = {
      insertError: errorMessages.insertError || 'There were problems while inserting, check if all the field are correctly filled.',
      updateError: errorMessages.updateError || 'There were problems while updating, check if all the field are correctly filled.',
      removeError: errorMessages.removeError || 'There were problems while deleting, check if there are some dependencies.',
      loadError: errorMessages.loadError || 'There were problems while loading the page, try to refresh the page.',
    };
    return {
      key: this.keys,
      byKey: key => firstValueFrom(this.get(key)),
      insert: async (values) => {
        try {
          return await firstValueFrom(this.insert(values));
        } catch (e) {
          console.error(e);
          throw new Error(errorMessages.insertError);
        }
      },
      update: async (key, values) => {
        for (const valueKey in values) {
          if (this.keys.find(oneKey => oneKey === valueKey)) {
            try {
              return await firstValueFrom(this.updateKeys(key, { ...key, ...values }));
            } catch (e) {
              console.error(e);
              throw new Error(errorMessages.updateError);
            }
          }
        }
        try {
          return await firstValueFrom(this.update({ ...key, ...values }));
        } catch (e) {
          console.error(e);
          throw new Error(errorMessages.updateError);
        }
      },
      remove: async (key) => {
        try {
          return await firstValueFrom(this.delete(key).pipe(map(() => { })));
        } catch (e) {
          console.error(e);
          throw new Error(errorMessages.removeError);
        }
      },
      load: async (loadOptions: any) => {
        const stringified = JSON.stringify(loadOptions);
        const params = {
          tableParams: btoa(stringified)
        };
        try {
          return await firstValueFrom(
            forkJoin([
              this.list(params),
              this.count(params)
            ]).pipe(
              map(([lista, conto]) => {
                return {
                  data: lista,
                  totalCount: conto
                };
              }
              ),
            ));
        } catch (e) {
          console.error(e);
          throw new Error(errorMessages.loadError);
        }

      }
    };
  }

  buildFormData(formData: FormData, data: any, parentKey?: string) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else if (data !== null && data !== undefined) {
      formData.append(parentKey, data);
    }
  }
}
