import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AbstractService } from './AbstractService';
import { MyCacheService } from './internal/MyCacheService';

export abstract class AbstractCachedService<T> extends AbstractService<T> {

  protected cache: MyCacheService<T>;

  constructor(protected http: HttpClient, cacheTime?: number) {
    super(http);
    this.cache = new MyCacheService<T>(undefined, cacheTime);
  }

  stringKeys(object: T | object | number | Array<number>) {
    if (this.keys.length === 1) {
      if (typeof object === 'string') {
        return object;
      } else if ( typeof object === 'number') {
        return object.toString();
      } else if (typeof object === 'object') {
        return this.stringKeys(object[this.keys[0]]);
      } else {
        return this.stringKeys(object[0]);
      }
    }

    const keys = { } as any;
    for (const one of this.keys) {
      if ( !object.hasOwnProperty(one) ) {
        return { message: `Key not found for Object: ${one}` };
      }
      keys[one] = object[one];
    }
    return JSON.stringify(keys);
  }

  list(queryParams?: any): Observable<T[]> {
    return super.list(queryParams).pipe(
      tap(list => {
        for (const one of list) {
          this.cache.set(this.stringKeys(one), one);
        }
      })
    );
  }

  get(ids: object | number | Array<number>, refresh?: boolean): Observable<T> {
    if (refresh) {
      this.cache.delete(this.stringKeys(ids));
    }
    return this.cache.get(this.stringKeys(ids), super.get(ids));
  }

  update(modelObject: T): Observable<T> {
    this.cache.delete(this.stringKeys(modelObject));
    return super.update(modelObject);
  }

  delete(modelObject: T): Observable<boolean> {
    this.cache.delete(this.stringKeys(modelObject));
    return super.delete(modelObject);
  }
}
