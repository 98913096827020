import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './AbstractService';
import { HttpClient } from '@angular/common/http';
import { Job } from '../model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobService extends AbstractService<Job> {
  protected mainEndpoint = environment.endpoint +  '/v1/job';

  constructor(protected http: HttpClient) {
    super(http);
  }

  listActive(): Observable<Job[]> {
    return this.http.get<Job[]>(`${this.mainEndpoint}/list/active`);
  }

  listToReturn(): Observable<Job[]> {
    return this.http.get<Job[]>(`${this.mainEndpoint}/list/to-return`);
  }

  close(job: Job): Observable<boolean> {
    return this.http.post<boolean>(`${this.mainEndpoint}/${job.id}/close`, undefined);
  }
}
