import { Component, OnInit, Input } from '@angular/core';
import { StorageObjectService } from 'src/app/services';
import { StorageObject } from 'src/app/model';

@Component({
  selector: 'app-object-status',
  templateUrl: './object-status.component.html'
})
export class ObjectStatusComponent implements OnInit {
  @Input() storageObject: StorageObject;

  missing: string[];

  constructor(
    private storageObjectService: StorageObjectService
  ) { }

  ngOnInit() {
    this.storageObjectService.checkStatus(this.storageObject.id).subscribe(
      res => {
        this.missing = Object.keys(res).filter(key => !res[key]);
      }
    );
  }
}
