import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  protected messages: Message[];
  protected subject: Subject<Message[]>;

  constructor() {
    this.messages = [];
    this.subject = new Subject();
  }

  loadMessages(messages: Message[]) {
    this.messages = messages;
  }

  addMessage(level: string, title: string, text: string, timeout?: number) {
    const message = {
      randId: Math.random() * 10000,
      level: level,
      title: title,
      message: text
    };
    this.messages.push(message);
    if (timeout) {
      setTimeout(() => { this.deleteMessage(message.randId); }, timeout);
    }
    this.subject.next(this.messages);
  }

  getMessages(): Observable<Message[]> {
    return this.subject.pipe(
      shareReplay(1)
    );
  }

  deleteMessage(randId: Number) {
    let index = -1;
    this.messages.find((message, i) => {
      if (message.randId === randId) {
        index = i;
        return true;
      }
      return false;
    });
    if (index >= 0) {
      this.messages.splice(index, 1);
      this.subject.next(this.messages);
    }
  }
}

export interface Message {
  randId: number;
  level: string;
  title: string;
  message: string;
}
