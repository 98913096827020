import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AuthGuard } from './guards';
import { NotFoundComponent } from './modules/shared-layout/not-found/not-found.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'account', loadChildren: () => import('./modules/recover-password/recover-password.module').then(m => m.RecoverPasswordModule)
  },
  {
    path: '', loadChildren: () => import('./modules/container/container.module').then(m => m.ContainerModule), canLoad: [AuthGuard]
  },
  {
    path: '**', component: NotFoundComponent
  },
];

const options: ExtraOptions = {
  onSameUrlNavigation: 'reload',
  enableTracing: !environment.production,
  scrollPositionRestoration: 'top'
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, options),
  ],
  providers: [

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
