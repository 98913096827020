import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { AlertBoxComponent } from './alert-box/alert-box.component';
import { AccountMenuComponent } from './account-menu/account-menu.component';
import { SwitchModeComponent } from './switch-mode/switch-mode.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog-component/confirmation-dialog.component';
import { ScanDisplayObjectDialogComponent } from './scan-display-object-dialog/scan-display-object-dialog.component';
import { ObjectStatusComponent } from './object-status/object-status.component';


const components = [
  NotFoundComponent,
  AlertBoxComponent,
  AccountMenuComponent,
  SwitchModeComponent,
  BreadcrumbComponent,
  ConfirmationDialogComponent,
  ObjectStatusComponent,
];
const entryComponents = [
  ConfirmationDialogComponent,
  ScanDisplayObjectDialogComponent
];

const modules = [
  MatButtonModule,
  MatDialogModule
];

@NgModule({
    declarations: [
        ...components,
        ...entryComponents
    ],
    imports: [
        CommonModule,
        RouterModule,
        ...modules
    ],
    providers: [],
    bootstrap: [],
    exports: [
        ...components,
        ...entryComponents
    ]
})
export class LayoutModule { }
