import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { AuthHelper } from '../helpers/auth.helper';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, private authHelper: AuthHelper) { }

  login(username: string, password: string): Observable<void> {
    const body = {
      username,
      password
    };
    return this.http.post(environment.endpoint + '/v1/auth/login', body).pipe(
      map((response: any) => {
        // login successful if there's a jwt token in the response
        if (response && response.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.authHelper.saveRefreshToken(response.refresh_token);
          this.authHelper.saveToken(response.access_token);
        }
      }),
      catchError((error: any) => {
        return throwError(error.error);
      })
    );
  }

  refreshToken(): Observable<boolean> {
    return this.http.post(environment.endpoint + '/v1/auth/token', { refresh_token: this.authHelper.getRefreshToken() })
      .pipe(
        map((response: any) => {
          if (response && response.access_token) {
            this.authHelper.saveToken(response.access_token);
            return true;
          } else {
            return false;
          }
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  logout(): void {
    this.authHelper.removeData();
  }

  getLoggedUserId() {
    return this.authHelper.getUserId();
  }

  changePassword(password: string, token: string): Observable<any> {
    return this.http.post(environment.endpoint + '/v1/auth/change-password', {
      password,
      token
    });
  }

  requestRecoverEmail(email: string): Observable<any> {
    return this.http.post(environment.endpoint + '/v1/auth/require-change', {
      email
    });
  }

  activateAccount(token: string): Observable<boolean> {
    return this.http.post(environment.endpoint + '/v1/auth/activate', {
      token
    }).pipe(
      map(res => true)
    );
  }
}
