import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Position } from '../model';
import { map, catchError, switchMap, concatMap, toArray } from 'rxjs/operators';
import { Observable, from, of } from 'rxjs';
import { StorageService } from './StorageService';
import { AbstractCachedService } from './AbstractCachedService';
@Injectable({
  providedIn: 'root'
})
export class PositionService extends AbstractCachedService<Position> {
  protected mainEndpoint = environment.endpoint + '/v1/position';
  constructor(protected http: HttpClient, protected storageService: StorageService) {
    super(http);
  }

  checkPosition(storageId: number, code: string): Observable<boolean> {
    return this.http.head(this.mainEndpoint, {
      params: {
        // tslint:disable-next-line:object-literal-key-quotes
        'storageId': storageId as unknown as string,
        code
      }
    }).pipe(
      map(res => false),
      catchError(err => of(true))
    );
  }

  composeObjects(rawObjects: any[]): Observable<Position[]> {
    return of(rawObjects).pipe(
      switchMap(objectList => this.storageService.list().pipe(map(() => objectList))),
      concatMap(objectList => from(objectList)),
      concatMap(object => this.composeObject(object)),
      toArray()
    );
  }

  composeObject(rawPositionObject: Position): Observable<Position> {
    return this.storageService.get(rawPositionObject.storageId).pipe(
      map(storage => {
        rawPositionObject.storage = storage;
        return rawPositionObject;
      })
    );
  }
}
