import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './AbstractService';
import { HttpClient } from '@angular/common/http';
import { StorageObjectEquipmentMaintenance } from '../model';
import { Observable } from 'rxjs';
import { DownloadService } from './DownloadService';

@Injectable({
  providedIn: 'root'
})
export class StorageObjectEquipmentMaintenanceService extends AbstractService<StorageObjectEquipmentMaintenance> {
  protected mainEndpoint = environment.endpoint + '/v1/equipment-maintenance';

  constructor(
    protected http: HttpClient,
    protected downloadService: DownloadService,
  ) {
    super(http);
  }

  insert(modelObject: StorageObjectEquipmentMaintenance): Observable<StorageObjectEquipmentMaintenance> {
    modelObject.date = this.dateToString(modelObject.date);
    modelObject.dueDate = this.dateToString(modelObject.dueDate);
    if (typeof modelObject.isOrdinary === 'boolean') {
      modelObject.isOrdinary = modelObject.isOrdinary ? 1 : 0;
    }
    const f = new FormData();
    for (const field in modelObject) {
      if (modelObject.hasOwnProperty(field)) {
        f.append(field, modelObject[field]);
      }
    }
    return this.http.post<StorageObjectEquipmentMaintenance>(this.mainEndpoint, f);
  }

  update(modelObject: StorageObjectEquipmentMaintenance): Observable<StorageObjectEquipmentMaintenance> {
    modelObject.date = this.dateToString(modelObject.date);
    modelObject.dueDate = this.dateToString(modelObject.dueDate);
    return super.update(modelObject);
  }

  download(modelObject: StorageObjectEquipmentMaintenance): void {
    this.downloadService.download(this.mainEndpoint + `/${modelObject.id}/download`);
  }
}
