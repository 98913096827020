import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './AbstractService';
import { HttpClient } from '@angular/common/http';
import { BasketObject } from '../model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BasketObjectService extends AbstractService<BasketObject> {
  protected mainEndpoint = environment.endpoint +  '/v1/basket-object';
  protected keys = ['basketId', 'objectId'];

  constructor(protected http: HttpClient) {
    super(http);
  }

  /**
   * @deprecated
   */
  add(basketObject: BasketObject): Observable<any> {
    return this.http.post(`${this.mainEndpoint}/add`, basketObject);
  }
}
