import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
interface TokenData {
  exp: number;
  user_id: number;
  is_admin: number;
  is_god: number;
}
@Injectable({
  providedIn: 'root'
})
export class AuthHelper {

  private tokenData: TokenData;
  private stringToken: string;

  getToken() {
    return this.stringToken;
  }

  hasToken() {
    return typeof this.stringToken === 'string';
  }

  getTokenData(): TokenData | false {
    if (this.hasToken()) {
      if (!this.tokenData) {
        this.extractTokenData();
      }
      return this.tokenData;
    } else {
      this.tokenData = undefined;
    }
    return false;
  }

  getUserId() {
    const data = this.getTokenData();
    if (data) {
      return data.user_id;
    } else {
      return false;
    }
  }

  isAdmin() {
    const data = this.getTokenData();
    if (data) {
      return data.is_god === 1;
    } else {
      return false;
    }
  }

  isGod() {
    const data = this.getTokenData();
    if (data) {
      return data.is_god === 1;
    } else {
      return false;
    }
  }

  isTokenValid() {
    const data = this.getTokenData();
    if (data && (data.exp * 1000) > (new Date()).getTime()) {
      return true;
    } else {
      return false;
    }
  }

  decodeTokenData(token: string) {
    return jwt_decode(token);
  }

  saveToken(token: string) {
    this.stringToken = token;
    // localStorage.setItem('access', token);
    this.extractTokenData();
    return true;
  }

  extractTokenData() {
    this.tokenData = this.decodeTokenData(this.getToken()) as TokenData;
  }

  removeAccess() {
    this.tokenData = undefined;
    this.stringToken = undefined;
    // localStorage.removeItem('access');
  }

  removeData() {
    this.removeAccess();
    localStorage.removeItem('refresh');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh');
  }

  saveRefreshToken(token: string) {
    localStorage.setItem('refresh', token);
  }

}
