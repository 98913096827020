import { Injectable } from '@angular/core';
import { StorageObjectMiscellaneous,
  } from '../model';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { StorageObjectTypeEnum } from '../model/storage-object.enum';
import { StorageObjectEquipmentService } from './StorageObjectEquipmentService';
import { StorageObjectMiscellaneousService } from './StorageObjectMiscellaneousService';
import { AnyStorageObject } from '../model/storage-object.types';
import { StorageObjectContainerService } from './StorageObjectContainerService';
import { StorageObjectService } from './StorageObjectService';

@Injectable({
  providedIn: 'root'
})
export class StorageObjectUtilService {

  constructor(
    private objectService: StorageObjectService,
    private equipmentObjectService: StorageObjectEquipmentService,
    private containerObjectService: StorageObjectContainerService,
    private miscellaneousObjectService: StorageObjectMiscellaneousService,
  ) {
  }

  getObjectName(
    storageObject: AnyStorageObject
  ): Observable<string> {
    return of(storageObject.name);
    switch (storageObject.type) {
      case StorageObjectTypeEnum.Container:
        break;
      case StorageObjectTypeEnum.Equipaggiamento:
      case StorageObjectTypeEnum.Componente:
        const miscellaneous = storageObject as StorageObjectMiscellaneous;
        if (miscellaneous.miscellaneous) {
          return of(miscellaneous.name);
        }
        return this.miscellaneousObjectService.get(storageObject.id)
          .pipe(
            tap(resEquipment => (storageObject as StorageObjectMiscellaneous).miscellaneous = resEquipment.miscellaneous),
            map(resEquipment => resEquipment.name)
          );
    }
  }

  get(objectId: number): Observable<AnyStorageObject> {
    return this.objectService.getExtended(objectId);
  }
}
