import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanLoad } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { AuthHelper } from '../helpers/auth.helper';
import { AuthService } from '../services/AuthService';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authHelper: AuthHelper, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (this.authHelper.isTokenValid()) {
      return true;
    } else if (this.authHelper.getRefreshToken()) {
      return this.authService.refreshToken().pipe(
        catchError((err: any) => {
          this.redirect(state);
          throw err;
        })
      );
    } else {
      this.redirect(state);
      return false;
    }
  }
  protected redirect(state: RouterStateSnapshot) {
    const queryParams = state && state.url ? { returnUrl: state.url } : {};
    this.router.navigate(['/login'], { queryParams });
  }

  canLoad(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
