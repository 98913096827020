import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractCachedService } from './AbstractCachedService';
import { HttpClient } from '@angular/common/http';
import { Storage } from '../model';

@Injectable({
  providedIn: 'root'
})
export class StorageService extends AbstractCachedService<Storage> {
  protected mainEndpoint = environment.endpoint +  '/v1/storage';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
