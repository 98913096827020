import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './AbstractService';
import { HttpClient } from '@angular/common/http';
import { Basket } from '../model';
import { tap, map, switchMap, mergeMap, toArray } from 'rxjs/operators';
import { of, Observable, BehaviorSubject, from } from 'rxjs';
import { BasketObjectService } from './BasketObjectService';

@Injectable({
  providedIn: 'root'
})
export class BasketService extends AbstractService<Basket> {
  protected mainEndpoint = environment.endpoint + '/v1/basket';

  protected basketList: Basket[];
  protected selectedBasket= new BehaviorSubject<Basket>(undefined);;

  constructor(
    protected http: HttpClient,
    protected basketObjectService: BasketObjectService
  ) {
    super(http);
  }

  insert(basket: Basket): Observable<Basket> {
    return super.insert(basket).pipe(
      tap(() => this.basketList = undefined),
      switchMap(one => this.listBaskets(basket.chargeListId).pipe(
        map(res => one)
      ))
    );
  }

  getSelectedBasket(chargeListId?: number): Observable<Basket> {
    if (!this.selectedBasket.value) {
      return this.listBaskets().pipe(
        tap(basketList => {
          if (chargeListId) {
            basketList = basketList.filter(basket => basket.chargeListId === chargeListId);
          }
          if (basketList.length > 0) {
            this.setSelectBasket(basketList[0]);
          }
        }),
        switchMap(res => this.selectedBasket)
      );
    }
    return this.selectedBasket;
  }

  setSelectBasket(basket?: Basket) {
    this.selectedBasket.next(basket);
  }

  listBaskets(chargeListId?: number, force?: boolean): Observable<Basket[]> {
    let basketList: Observable<Basket[]>;
    if (!this.basketList || force) {
      basketList = super.list().pipe(
        tap(res => this.basketList = res),
      );
    } else {
      basketList = of(this.basketList);
    }
    return basketList.pipe(
      map(
        list => chargeListId ? list.filter(basket => basket.chargeListId === chargeListId) : list
      )
    );
  }

  getReservedQuantityForChargeList(chargeListId: number): Observable<number> {
    return this.list({
      chargeListId
    }).pipe(
      mergeMap(baskets => from(baskets)),
      mergeMap(basket => this.basketObjectService.list( {
        basketId: basket.id
      })),
      mergeMap(basketObjectList => from(basketObjectList)),
      toArray(),
      map(basketObjectList => {
        if (basketObjectList.length > 0) {
          return basketObjectList.map((one) => one.qty).reduce((before, current) => before + current);
        }
        return 0;
      })
    );
  }
}
