import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './AbstractService';
import { HttpClient } from '@angular/common/http';
import { StorageObjectMiscellaneous } from '../model';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { StorageObjectService } from './StorageObjectService';
import { DownloadService } from './DownloadService';
import { CustomStoreOptions } from 'devextreme/data/custom_store';

@Injectable({
  providedIn: 'root'
})
export class StorageObjectMiscellaneousService extends AbstractService<StorageObjectMiscellaneous> {
  protected mainEndpoint = environment.endpoint + '/v1/miscellaneous';

  constructor(
    protected http: HttpClient,
    protected storageObjectService: StorageObjectService,
    protected downloadService: DownloadService,
    ) {
    super(http);
  }

  insert(modelObject: StorageObjectMiscellaneous): Observable<StorageObjectMiscellaneous> {
    const f = new FormData();
    this.buildFormData(f, modelObject);
    return this.http.post<StorageObjectMiscellaneous>(this.mainEndpoint, f).pipe(
      switchMap(object => this.composeObject(object))
    );
  }

  update(modelObject: StorageObjectMiscellaneous): Observable<StorageObjectMiscellaneous> {
    const f = new FormData();
    this.buildFormData(f, modelObject);
    return this.http.post<StorageObjectMiscellaneous>(this.mainEndpoint + '/update', f).pipe(
      switchMap(object => this.composeObject(object, true))
    );
  }

  composeObject(rawStorageObject: any, refresh = false): Observable<StorageObjectMiscellaneous> {
    return this.storageObjectService.get(rawStorageObject.objectId, refresh).pipe(
      map(storageObject => storageObject as StorageObjectMiscellaneous),
      map(storageObject => {
        storageObject.miscellaneous = rawStorageObject;
        return storageObject;
      })
    );
  }

  composeObjects(rawStorageObjects: any[]): Observable<StorageObjectMiscellaneous[]> {
    return this.storageObjectService.list({ type: 'miscellaneous' }).pipe(
      map(storageObjectServiceList => rawStorageObjects.map(rawStorageObject => {
        const storageObject = storageObjectServiceList.find(one => one.id === rawStorageObject.objectId) as StorageObjectMiscellaneous;
        storageObject.miscellaneous = rawStorageObject;
        return storageObject;
      })
      )
    );
  }

  downloadCertificate(modelObject: StorageObjectMiscellaneous): void {
    this.storageObjectService.downloadCertificate(modelObject);
  }

  getAvailabilitySummary(params: any) {
    return this.http.get<number>(`${this.mainEndpoint}/availability-summary`, { params });
  }

  dataSource(errorMessages?: {
    insertError: string;
    updateError: string;
    removeError: string;
    loadError: string;
  }): CustomStoreOptions {
    return {
      ...super.dataSource(errorMessages),
      load: async (loadOptions: any) => {
        const stringified = JSON.stringify(loadOptions);
        const params = {
          tableParams: btoa(stringified)
        };
        try {
          return await forkJoin([
            this.list(params),
            this.count(params),
            this.getAvailabilitySummary(params)
          ]).pipe(
            map(([lista, conto, disponibilita]) => {
              return {
                data: lista,
                totalCount: conto,
                summary: [disponibilita]
              };
            }
            ),
          ).toPromise();
        } catch (e) {
          console.error(e);
          throw new Error(errorMessages.loadError);
        }

      }
    }
  }
}
