import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './AbstractService';
import { HttpClient } from '@angular/common/http';
import { StorageObjectEquipment } from '../model';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { StorageObjectService } from './StorageObjectService';
import { DownloadService } from './DownloadService';
import { CustomStoreOptions } from 'devextreme/data/custom_store';

@Injectable({
  providedIn: 'root'
})
export class StorageObjectEquipmentService extends AbstractService<StorageObjectEquipment> {
  protected mainEndpoint = environment.endpoint + '/v1/equipment';

  constructor(
    protected http: HttpClient,
    protected storageObjectService: StorageObjectService,
    protected downloadService: DownloadService) {
    super(http);
  }

  insert(modelObject: StorageObjectEquipment): Observable<StorageObjectEquipment> {
    modelObject.equipment.nextMaintenanceDate = this.dateToString(modelObject.equipment.nextMaintenanceDate);
    const f = new FormData();
    this.buildFormData(f, modelObject);
    return this.http.post<StorageObjectEquipment>(this.mainEndpoint, f).pipe(
      switchMap(object => this.composeObject(object))
    );
  }

  update(modelObject: Partial<StorageObjectEquipment>): Observable<StorageObjectEquipment> {
    modelObject.equipment.nextMaintenanceDate = this.dateToString(modelObject.equipment.nextMaintenanceDate);
    const f = new FormData();
    this.buildFormData(f, modelObject);
    return this.http.post<StorageObjectEquipment>(this.mainEndpoint + '/update', f).pipe(
      switchMap(object => this.composeObject(object, true))
    );
  }

  composeObject(rawStorageObject: any, refresh = false): Observable<StorageObjectEquipment> {
    return this.storageObjectService.get(rawStorageObject.objectId, refresh).pipe(
      map(storageObject => storageObject as StorageObjectEquipment),
      map(storageObjectEquipment => {
        storageObjectEquipment.equipment = rawStorageObject;
        return storageObjectEquipment;
      })
    );
  }

  composeObjects(rawStorageObjects: any[]): Observable<StorageObjectEquipment[]> {
    return this.storageObjectService.list({ type: 'equipment' }).pipe(
      map(storageObjectServiceList => rawStorageObjects.map(rawStorageObject => {
          try {
            const storageObject = storageObjectServiceList.find(one => one.id === rawStorageObject.objectId) as StorageObjectEquipment;
            if (!storageObject) {
              return undefined;
            }
            storageObject.equipment = rawStorageObject;
            return storageObject;
          } catch (e) {
            console.warn(e, rawStorageObject);
          }
        }).filter(one => one !== undefined)
      )
    );
  }

  downloadCertificate(modelObject: StorageObjectEquipment): void {
    this.storageObjectService.downloadCertificate(modelObject);
  }

  downloadDataSheet(modelObject: StorageObjectEquipment): void {
    this.downloadService.download(this.mainEndpoint + `/${modelObject.id}/data-sheet-download`);
  }

  regenerateDataSheet(modelObject: StorageObjectEquipment): void {
    const sub = this.http.post(this.mainEndpoint + `/${modelObject.id}/data-sheet-regenerate`, undefined).subscribe(
      res => setTimeout(() => sub.unsubscribe())
    );
  }

  getAvailabilitySummary(params: any) {
    return this.http.get<number>(`${this.mainEndpoint}/availability-summary`, { params });
  }

  dataSource(errorMessages?: {
    insertError: string;
    updateError: string;
    removeError: string;
    loadError: string;
  }): CustomStoreOptions {
    return {
      ...super.dataSource(errorMessages),
      load: async (loadOptions: any) => {
        const stringified = JSON.stringify(loadOptions);
        const params = {
          tableParams: btoa(stringified)
        };
        try {
          return await forkJoin([
            this.list(params),
            this.count(params),
            this.getAvailabilitySummary(params)
          ]).pipe(
            map(([lista, conto, disponibilita]) => {
              return {
                data: lista,
                totalCount: conto,
                summary: [disponibilita]
              };
            }
            ),
          ).toPromise();
        } catch (e) {
          console.error(e);
          throw new Error(errorMessages.loadError);
        }

      }
    }
  }
}
