import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './AbstractService';
import { HttpClient } from '@angular/common/http';
import { PositionObject } from '../model';
import { Observable, from, forkJoin, of } from 'rxjs';
import { switchMap, map, toArray, concatMap } from 'rxjs/operators';
import { StorageObjectService } from './StorageObjectService';
import { AnyStorageObject } from '../model/storage-object.types';
import { MyCacheService } from './internal/MyCacheService';
import { PositionService } from './PositionService';

@Injectable({
  providedIn: 'root'
})
export class PositionObjectService extends AbstractService<PositionObject> {
  protected mainEndpoint = environment.endpoint + '/v1/position-object';
  protected keys = ['positionId', 'objectId'];
  protected availabilityCache: MyCacheService<PositionObject[]>;

  constructor(
    protected http: HttpClient,
    protected storageObjectService: StorageObjectService,
    protected positionService: PositionService) {
      super(http);
      this.availabilityCache = new MyCacheService<PositionObject[]>(undefined, 60000);
  }

  composeObject(positionObject: PositionObject) {
    return forkJoin([
      positionObject.object ? of(positionObject.object) : this.storageObjectService.get(positionObject.objectId),
      positionObject.position ? of(positionObject.position) : this.positionService.get(positionObject.positionId)
    ]).pipe(
      map(([object, position]) => {
        positionObject.object = object;
        positionObject.position = position;
        return positionObject;
      })
    );
  }

  listWithObjects(params: any): Observable<PositionObject[]> {
    return this.list(params).pipe(
      concatMap(array => from(array)),
      switchMap(positionObject => this.storageObjectService.getExtended(positionObject.objectId).pipe(
        map((storageObject: AnyStorageObject) => {
          positionObject.object = storageObject;
          return positionObject;
        })
      )),
      toArray()
    );
  }

  getOneWithObject(keys: any): Observable<PositionObject> {
    return this.get(keys).pipe(
      switchMap(positionObject => this.storageObjectService.getExtended(positionObject.objectId).pipe(
        map((storageObject: AnyStorageObject) => {
          positionObject.object = storageObject;
          return positionObject;
        })
      ))
    );
  }

  load(positionObject: PositionObject): Observable<PositionObject> {
    return this.http.post<PositionObject>(`${this.mainEndpoint}/load`, positionObject);
  }
}
