import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  @Input()
  target: string;

  @Input()
  name: string;

  @Input()
  title: string;

  @Input()
  mode: 'mobile' | 'administration';

  constructor(private router: Router) {
  }
}
