import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  Job, Storage, StorageObject,
  StorageObjectEquipment,
  StorageObjectMiscellaneous
} from '../model';
import { StorageObjectTypeEnum } from '../model/storage-object.enum';
import { AnyStorageObject } from '../model/storage-object.types';
import { AbstractCachedService } from './AbstractCachedService';
import { ChargeListService } from './ChargeListService';
import { DownloadService } from './DownloadService';
import { MyCacheService } from './internal/MyCacheService';

@Injectable({
  providedIn: 'root'
})
export class StorageObjectService extends AbstractCachedService<StorageObject> {

  protected mainEndpoint = environment.endpoint + '/v1/object';
  protected extraCache = new MyCacheService<any>(undefined, 1000 * 60 * 5);

  constructor(
    protected http: HttpClient,
    protected chargeListService: ChargeListService,
    protected downloadService: DownloadService,
  ) {
    super(http);
  }

  typesDataStore() {
    return [
      {
        key: StorageObjectTypeEnum.Componente,
        value: 'Miscellaneous'
      },
      {
        key: StorageObjectTypeEnum.Equipaggiamento,
        value: 'Equipment'
      },
      {
        key: StorageObjectTypeEnum.Container,
        value: 'Boxes'
      }
    ];
  }

  getExtended(id: number): Observable<AnyStorageObject> {
    return this.http.get(`${this.mainEndpoint}/${id}`,
      { params: { extended: 'true' } }).pipe(
        map((object: StorageObject) => {
          switch (object.type) {
            case StorageObjectTypeEnum.Componente:
              return object as StorageObjectMiscellaneous;
            case StorageObjectTypeEnum.Equipaggiamento:
              return object as StorageObjectEquipment;
            case StorageObjectTypeEnum.Container:
              return object as StorageObjectMiscellaneous;
          }
        })
      );
  }

  getAvailableQty(object: StorageObject, job?: Job, storage?: Storage): Observable<number> {
    const params = {} as Params;
    if (job && job.id) {
      params.jobId = job.id;
    }
    if (storage && storage.id) {
      params.storageId = storage.id;
    }

    return this.extraCache.get(
      'availability-' + JSON.stringify({ ...params, id: object.id }),
      this.http.get(`${this.mainEndpoint}/${object.id}/available-quantities`, {
        params
      }).pipe(
        // tap(res => console.log(object.id, res))
      )
    );
  }

  getSentQty(object: StorageObject, job: Job): Observable<number> {
    return this.http.get(`${this.mainEndpoint}/${object.id}/sent-to/${job.id}`).pipe(
      map(res => res as number)
    )
  }

  addQuantityToBasket(
    objectId: number,
    positionId: number,
    basketId: number,
    qty: number,
    chargeListId?: number,
    unload: boolean = false): Observable<boolean> {
    return this.http.post<boolean>(`${this.mainEndpoint}/add-to-basket`, {
      objectId,
      positionId,
      basketId,
      qty,
      chargeListId,
      unload
    });
  }

  addQuantityToPosition(
    objectId: number,
    positionId: number,
    basketId: number,
    qty: number,
    chargeListId?: number): Observable<boolean> {
    return this.http.post<boolean>(`${this.mainEndpoint}/add-to-position`, {
      objectId,
      positionId,
      basketId,
      qty,
      chargeListId
    });
  }

  loadToReturn(
    objectId: number,
    basketId: number,
    qty: number,
    chargeListId: number): Observable<boolean> {
    return this.http.put<boolean>(`${this.mainEndpoint}/load-return`, {
      objectId,
      basketId,
      qty,
      chargeListId
    });
  }

  checkStatus(objectId: number): Observable<string[]> {
    return this.extraCache.get('status-' + objectId, this.http.get<string[]>(`${this.mainEndpoint}/${objectId}/check-status`));
  }

  downloadCertificate(modelObject: StorageObject): void {
    this.downloadService.download(this.mainEndpoint + `/${modelObject.id}/download-certificate`);
  }

  clone(storageObject: StorageObject, copiesNumber: number, withAttributes: boolean, withFiles: boolean, withMaintenance: boolean): Observable<void> {
    return this.http.post<void>(`${this.mainEndpoint}/clone`, {
      objectId: storageObject.id,
      copies: copiesNumber,
      withAttributes,
      withFiles,
      withMaintenance
    });
  }
}
