import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QrTag } from 'src/app/model/qr.model';
import { StorageObjectService, PositionObjectService, PositionService, ChargeListService, ChargeListObjectService } from 'src/app/services';
import { StorageObject, PositionObject, ChargeList } from 'src/app/model';
import { Observable, from, forkJoin } from 'rxjs';
import { StorageObjectUtilService } from 'src/app/services/StorageObjectUtilService';
import { map, tap, concatMap, toArray, mergeMap, filter } from 'rxjs/operators';
import { ChargeListObject } from 'src/app/model/charge-list-object.model';

@Component({
  selector: 'app-scan-display-object-dialog',
  templateUrl: './scan-display-object-dialog.component.html'
})
export class ScanDisplayObjectDialogComponent implements OnInit {

  storageObject: StorageObject;
  objectPositions: PositionObject[];
  chargeListObjects = [] as ChargeListObject[]
  show = false;

  constructor(
    protected storageObjectService: StorageObjectService,
    protected positionObjectService: PositionObjectService,
    protected chargeListService: ChargeListService,
    protected chargeListObjectService: ChargeListObjectService,
    protected positionService: PositionService,
    public storageObjectUtilService: StorageObjectUtilService,
    public dialogRef: MatDialogRef<ScanDisplayObjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public objectId: number) { }

  ngOnInit() {
    const sub = forkJoin([
      this.loadObject(),
      this.loadPositions(),
      this.loadChargeLists()
    ]).subscribe(() => {
      this.show = true;
      setTimeout(() => sub.unsubscribe());
    });
  }

  loadObject() {
    return this.storageObjectService.getExtended(this.objectId)
      .pipe(
        tap(storageObject => this.storageObject = storageObject)
      );
  }

  loadPositions() {
    return this.positionObjectService.list({
      objectId: this.objectId
    }).pipe(
      concatMap(list => from(list)),
      // filter(one => one.qty > 0),
      concatMap(one => this.positionService.get(one.positionId).pipe(
        map(position => {
          one.position = position;
          return one;
        })
      )),
      toArray(),
      tap(objectPositions => this.objectPositions = objectPositions)
    );
  }

  loadChargeLists() {
    return this.chargeListObjectService.list({
      objectId: this.objectId,
      extraProperties: 'chargeList'
    }).pipe(
      tap(chargeListObjects => this.chargeListObjects = chargeListObjects)
    )
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
