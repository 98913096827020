import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { finalize, scan } from 'rxjs/operators';
import { Download } from '../model/download.model';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  downloadArr = [] as Observable<Download>[];

  constructor(protected http: HttpClient) { }

  /**
   * @param endpoint to call to get blob
   * @param newTab true or undefined to open in new tab, false to download
   */
  download(endpoint: string, downloadName?: string) {
    // const URL = window.hasOwnProperty('webkitURL') ? window.webkitURL : window.URL ;
    const obs = this.http.get(endpoint, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    }).pipe(
      scan((previous: Download, event: HttpEvent<Blob>): Download => {
        if (event.type === HttpEventType.DownloadProgress
          || event.type === HttpEventType.UploadProgress) {
          return {
            progress: event.total
              ? Math.round((100 * event.loaded) / event.total)
              : previous.progress,
            state: 'IN_PROGRESS',
            content: null
          }
        }
        if (event.type === HttpEventType.Response) {

          const url = window.URL.createObjectURL(event.body);
          // window.open(url);
          // const link = document.createElement('a');
          // link.href = url
          // link.download = downloadName;
          // link.click();
          saveAs(event.body, downloadName);
          return {
            progress: 100,
            state: 'DONE',
            content: event.body
          }
        }
        return previous
      },
      { state: 'PENDING', progress: 0, content: null },
      ),
      // startWith({ state: 'PENDING', progress: 0, content: null } as Download),
      finalize(() => {
        const i = this.downloadArr.indexOf(obs);
        this.downloadArr.splice(i, 1);
      })
    );
    this.downloadArr.push(obs);
  }
}
