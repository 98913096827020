import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './AbstractService';
import { HttpClient } from '@angular/common/http';
import { ChargeList } from '../model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DownloadService } from './DownloadService';
import { BasketService } from './BasketService';
import { PreparationStatus } from '../model/preparation-status.model';

@Injectable({
  providedIn: 'root'
})
export class ChargeListService extends AbstractService<ChargeList> {
  protected mainEndpoint = environment.endpoint + '/v1/charge-list';

  constructor(
    protected http: HttpClient,
    protected downloadService: DownloadService,
    protected basketService: BasketService,
  ) {
    super(http);
  }

  listFilled(jobId: number): Observable<ChargeList[]> {
    return this.http.get(this.mainEndpoint + `/filled-list/job/${jobId}`) as Observable<ChargeList[]>;
  }

  listByJob(jobId: number): Observable<ChargeList[]> {
    return this.list({
      jobId
    });
  }

  activeListForJob(jobId: number, isReturn?: boolean, includeClosed?: boolean): Observable<ChargeList[]> {
    return this.list({
      jobId,
      isReturn: isReturn ? 1 : 0
    }).pipe(
      map(list => includeClosed ? list : list.filter(one => !one.closeDate))
    );
  }

  getPreparationStatus(chargeListId: number): Observable<PreparationStatus> {
    return this.http.get(`${this.mainEndpoint}/${chargeListId}/preparation-status`) as unknown as any;
  }

  markAsShipped(chargeList: Pick<ChargeList, 'id'>) {
    return this.http.post(`${this.mainEndpoint}/${chargeList.id}/close`, {});
  }

  downloadProFormaPhotographList(chargeList: Pick<ChargeList, 'id'>) {
    return this.downloadService.download(`${this.mainEndpoint}/${chargeList.id}/download/pro-forma-photograph-list`, `pro-forma photograph list ${chargeList.id}.pdf`);
  }
  downloadPhotographList(chargeList: Pick<ChargeList, 'id'>) {
    return this.downloadService.download(`${this.mainEndpoint}/${chargeList.id}/download/photograph-list`, `photograph list ${chargeList.id}.pdf`);
  }
  downloadChargeList(chargeList: Pick<ChargeList, 'id'>) {
    return this.downloadService.download(`${this.mainEndpoint}/${chargeList.id}/download/charge-list`, `charge list ${chargeList.id}.csv`);
  }
  downloadBill(chargeList: Pick<ChargeList, 'id'>) {
    return this.downloadService.download(`${this.mainEndpoint}/${chargeList.id}/download/bill-file`, `bill ${chargeList.id}.pdf`);
  }
  downloadProFormaInvoice(chargeList: Pick<ChargeList, 'id' | 'proFormaInvoiceNumber'>) {
    return this.downloadService.download(`${this.mainEndpoint}/${chargeList.id}/download/pro-forma-invoice-file`, `pro forma invoice ${chargeList.id} - ${chargeList.proFormaInvoiceNumber}.pdf`);
  }
  downloadInvoice(chargeList: Pick<ChargeList, 'id' | 'proFormaInvoiceNumber'>) {
    return this.downloadService.download(`${this.mainEndpoint}/${chargeList.id}/download/invoice-file`, `invoice ${chargeList.id} - ${chargeList.proFormaInvoiceNumber}.pdf`);
  }


  generateProFormaPhotographList(chargeList: ChargeList) {
    return this.http.post(`${this.mainEndpoint}/${chargeList.id}/generate/pro-forma-photograph-list`, {});
  }
  generatePhotographList(chargeList: ChargeList) {
    return this.http.post(`${this.mainEndpoint}/${chargeList.id}/generate/photograph-list`, {});
  }
  generateChargeList(chargeList: ChargeList) {
    return this.http.post(`${this.mainEndpoint}/${chargeList.id}/generate/charge-list`, {});
  }
  generateBill(chargeList: ChargeList) {
    return this.http.post(`${this.mainEndpoint}/${chargeList.id}/generate/bill-file`, {});
  }
  generateProFormaInvoice(chargeList: ChargeList) {
    return this.http.post(`${this.mainEndpoint}/${chargeList.id}/generate/pro-forma-invoice-file`, {});
  }
  generateInvoice(chargeList: ChargeList) {
    return this.http.post(`${this.mainEndpoint}/${chargeList.id}/generate/invoice-file`, {});
  }
}
