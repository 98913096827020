import { ApplicationRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, timer } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'TechFem Magazzino';

  constructor(
    private swUpdate: SwUpdate,
    private snackBar: MatSnackBar,
    private appRef: ApplicationRef
  ) { }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = timer(0, 6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(() =>
        this.swUpdate.checkForUpdate()
          .then(
            res => res && this.swUpdate.activateUpdate()
              .then(
                res2 => window.location.reload()
              )
          )
      );
      console.log('nuovo modo di aggiornare');
      this.swUpdate.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(evt => {
          document.location.reload();
        });


      this.swUpdate.unrecoverable.subscribe(event => {
        console.warn('unrecoverable', event);
        document.location.reload();
      })
      // this.swUpdate.activated.subscribe(event => {
      //   console.log('old version was', event.previous);
      //   console.log('new version is', event.current);
      // });
      // this.swUpdate.available.subscribe(() => {
      //   this.snackBar.open('New version available. Load New Version?', 'Update')
      //     .onAction().subscribe(() => window.location.reload())
      // });
    }
  }
}
