import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './AbstractService';
import { HttpClient } from '@angular/common/http';
import { StorageObjectAttachment } from '../model';
import { Observable } from 'rxjs';
import { DownloadService } from './DownloadService';

@Injectable({
  providedIn: 'root'
})
export class StorageObjectAttachmentService extends AbstractService<StorageObjectAttachment> {
  protected mainEndpoint = environment.endpoint + '/v1/object-attachment';

  constructor(protected http: HttpClient, protected downloadService: DownloadService) {
    super(http);
  }

  insert(modelObject: StorageObjectAttachment): Observable<StorageObjectAttachment> {
    modelObject.expirationDate = this.dateToString(modelObject.expirationDate);
    const f = new FormData();
    for (const field in modelObject) {
      if (modelObject.hasOwnProperty(field) && modelObject[field] !== undefined && modelObject[field] !== null) {
        f.append(field, modelObject[field]);
      }
    }
    return this.http.post<StorageObjectAttachment>(this.mainEndpoint, f);
  }

  update(modelObject: StorageObjectAttachment): Observable<StorageObjectAttachment> {
    modelObject.expirationDate = this.dateToString(modelObject.expirationDate);
    return super.update(modelObject);
  }

  download(modelObject: StorageObjectAttachment): void {
    const ext = modelObject.file.split('.').at(-1);
    this.downloadService.download(this.mainEndpoint + `/${modelObject.id}/download`, modelObject.type + '.' + ext)
  }
}
