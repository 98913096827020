import { Component, OnInit } from '@angular/core';
import { AlertService, Message } from 'src/app/services/internal/AlertService';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html'
})
export class AlertBoxComponent implements OnInit {
  messages: Observable<Message[]>;

  constructor(
      public alertService: AlertService
  ) {}

  ngOnInit() {
    this.messages = this.alertService.getMessages();
  }

  delete(randId) {
    this.alertService.deleteMessage(randId);
  }
}
