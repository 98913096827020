import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { shareReplay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private VAPID_PUBLIC_KEY: Observable<string>;

  constructor(private http: HttpClient,
    // private swPush: SwPush
    ) {
    this.getVapidKey().subscribe();
  }

  subscribe(object: any): Observable<any> {
    return this.http.post(environment.endpoint + '/v1/notification/register', {
      type: 'web',
      token: object
    });
  }

  unsubscribe(object): Observable<any> {
    return this.http.post(environment.endpoint + '/v1/notification/unregister', {
      type: 'web',
      token: object
    });
  }

  getVapidKey(): Observable<string> {
    if (!this.VAPID_PUBLIC_KEY) {
      this.VAPID_PUBLIC_KEY = this.http.get(environment.endpoint + '/v1/notification/keys').pipe(
        map((keys: any) => keys.web),
        shareReplay(1)
      );
    }
    return this.VAPID_PUBLIC_KEY;
  }

  handleNotifications() {
    // this.swPush.messages.subscribe(
    //   (notification: any) => {
    //     console.log('received push message', notification);
    //   },
    //   err => {
    //     console.error(err);
    //   }
    // );
  }

  subscribeToNotification() {
    // this.getVapidKey().subscribe(
    //   key => {
    //     this.swPush.requestSubscription({
    //       serverPublicKey: key
    //     }).then(sub => this.subscribe(sub).subscribe())
    //       .catch(err => console.error('Could not subscribe to notifications', err));
    //     this.handleNotifications();
    //   });
  }

  urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
}
